export const projects = [
  {
    id: 1,
    link: "https://www.youtube.com/embed/hOGkdoRL1oA",
  },
  {
    id: 2,
    link: "https://www.youtube.com/embed/TfdJhcw-w5w",
  },
  {
    id: 3,
    link: "https://www.youtube.com/embed/GpRaH7vO1tE",
  },
];
